import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Styled from "styled-components"
import * as actions from "../../../../redux/actions"
import { connect } from "react-redux"

import Layout from "../../../../components/Layout"
import SEO from "../../../../components/Seo"
import RuledBreak from "../../../../components/RuledBreak"
import AbsText from "../../../../components/AbsText"
import { Danger as Err } from "../../../../components/Form"
import { ButtonTrace } from "../../../../components/Button"
import { BallTriangle as Loading } from "../../../../components/Common/loaders"

import { FormatDateM, FormatDateD } from "../../../../utils"

const TrainingDates = Styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding-left: 0;
    li {
        border: 2px solid black;
        padding: 10px;
        margin: 10px;
        min-width: 410px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
const UL = Styled.ul`
    list-style: none;
    text-align: center;
    li {
        text-align: left;
        text-transform: uppercase;
    }
`
const P = Styled.p`
    text-align: center;
    text-transform: uppercase;
`

const PrivateTrainings = ({
  data,
  loading,
  getFLP2DayTrainings,
  groupDates,
  groupDatesError,
}) => {
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getFLP2DayTrainings(true)
    }
    return () => {
      mounted = false
    }
  }, [])

  const renderTrainingDates = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-md-12 text-center">
            <Loading className="ball-tri-loader" />
          </div>
        </div>
      )
    }

    if (groupDates) {
      const left = []
      const right = []
      const numberOf = groupDates.length
      if (numberOf % 2 === 0) {
        // even split
        const leftCount = numberOf / 2
        for (let i = 0; i < leftCount; i++) {
          const { _id, city, state, spots, dateStart, dateEnd } = groupDates[i]
          const month = FormatDateM(dateStart)
          const days = FormatDateD(dateStart, dateEnd)
          left.push(
            <li key={_id}>
              <h2>
                {city}, {state}
              </h2>
              <h3>{days}</h3>
              <h2>{month}</h2>
              <h3>Spots: {spots}</h3>
            </li>
          )
        }
        for (let j = leftCount; j < numberOf; j++) {
          const { _id, city, state, spots, dateStart, dateEnd } = groupDates[j]
          const month = FormatDateM(dateStart)
          const days = FormatDateD(dateStart, dateEnd)
          right.push(
            <li key={_id}>
              <h2>
                {city}, {state}
              </h2>
              <h3>{days}</h3>
              <h2>{month}</h2>
              <h3>Spots: {spots}</h3>
            </li>
          )
        }
      } else {
        // NOT even
        const leftCount = Math.floor(numberOf / 2)
        for (let i = 0; i < leftCount; i++) {
          const { _id, city, state, spots, dateStart, dateEnd } = groupDates[i]
          const month = FormatDateM(dateStart)
          const days = FormatDateD(dateStart, dateEnd)
          left.push(
            <li key={_id}>
              <h2>
                {city}, {state}
              </h2>
              <h3>{days}</h3>
              <h2>{month}</h2>
              <h3>Spots: {spots}</h3>
            </li>
          )
        }
        for (let j = leftCount; j < numberOf; j++) {
          const { _id, city, state, spots, dateStart, dateEnd } = groupDates[j]
          const month = FormatDateM(dateStart)
          const days = FormatDateD(dateStart, dateEnd)
          right.push(
            <li key={_id}>
              <h2>
                {city}, {state}
              </h2>
              <h3>{days}</h3>
              <h2>{month}</h2>
              <h3>Spots: {spots}</h3>
            </li>
          )
        }
      }

      return (
        <div className="row">
          <div className="col-md-6 text-center">
            <ButtonTrace
              to="/education/trainings/private/register"
              btn={3}
              className="mb-2"
            >
              Register Now
            </ButtonTrace>
            <TrainingDates>{left}</TrainingDates>
            <ButtonTrace
              to="/education/trainings/private/register"
              btn={3}
              className="mt-2 d-none d-md-inline-block"
            >
              Register Now
            </ButtonTrace>
          </div>
          <div className="col-md-6 text-center">
            <ButtonTrace
              to="/education/trainings/private/register"
              btn={3}
              className="mb-2"
            >
              Register Now
            </ButtonTrace>
            <TrainingDates>{right}</TrainingDates>
            <ButtonTrace
              to="/education/trainings/private/register"
              btn={3}
              className="mt-2"
            >
              Register Now
            </ButtonTrace>
          </div>
        </div>
      )
    } else if (groupDatesError) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Err>{groupDatesError}</Err>
          </div>
        </div>
      )
    }
  }

  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO
        title="Education | Private Trainings"
        keywords={["Education", "Learning", "Private Trainings"]}
      />
      {/* ----------------------------------------------------------------------- */}
      <div className="row my-5">
        <div className="col-md-12 py-3">
          <RuledBreak>Private Trainings</RuledBreak>
          <h3 className="text-center">
            <i>1 Student Maximum</i>
          </h3>
          <h4 className="text-center">
            <i>($2,500 per student)</i>
          </h4>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      <AbsText top={50} size={100}>
        2020 Dates
      </AbsText>
      {/* ----------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-md-6 col-lg-5 mr-auto ml-auto py-2">
          <h3 className="text-center">Subjects Covered In Class:</h3>
          <UL>
            <li>- Proper Hand Placement</li>
            <li>- Advanced Fanning On The Strip Techniques</li>
            <li>- Fiercely Increase Fanning Speed</li>
            <li>- Apple These Techniques To Your Business's Advantage</li>
            <li>- Smartphone Photo Editing</li>
            <li>- Step-By-Step In Depth Training</li>
            <li>- Extensive Skin Enhancement Photo Editing</li>
            <li>- Know-Hows Using A Mobile App And Stylus</li>
            <li>- Lighting, Angles, Warm vs Cool, Model Posing + Direction</li>
            <li>- Go Over Camera Settings + Lenses For Smartphone Camera</li>
            <li>- Study These Techniques On Real Lash Models</li>
            <li>- Master Smartphone Videography + Editing</li>
          </UL>
        </div>
        {/* ----------------------------------------------------------------------- */}
        <div className="col-md-6 col-lg-5 mr-auto ml-auto py-2">
          <h3 className="text-center">Kit Provided With Course:</h3>
          <UL>
            <li>- 2 VOLUME TWEEZERS</li>
            <li>- LASH TILE</li>
            <li>- LASH ADHESIVE</li>
            <li>- 3 DIFFERENT TAPES</li>
            <li>- 2 LASH TRAYS OF YOUR CHOICE (M, L+, L, CC, or D)</li>
            <li>- ATTACHABLE SMARTPHONE CAMERA LENSES</li>
            <li>- STYLUS</li>
            <li>- PRO-MADE LASH TRAY</li>
          </UL>
        </div>
        {groupDates.length > 0 && (
          <div className="col-12 py-2 text-center">
            <ButtonTrace
              to="/education/trainings/private/register"
              btn={3}
              className="mb-2"
            >
              Register Now
            </ButtonTrace>
          </div>
        )}
        <div className="col-10 col-md-6 mr-auto ml-auto py-2">
          <h3 className="text-center">Final Goal Of This Course:</h3>
          <P>
            To fiercely increase fanning speed, become established in smartphone
            photo editing, make lighting &amp; angles your b*tch, and master
            smartphone videography techniques.
          </P>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      {renderTrainingDates()}
    </Layout>
  )
}

export const query = graphql`
  query privateTrainingsQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    loading: state.groupTraining.loading,
    groupDates: state.groupTraining.groupDates,
    groupDatesError: state.groupTraining.groupDatesError,
  }
}

export default connect(
  MapStateToProps,
  actions
)(PrivateTrainings)
